import { DismissibleChangeLogModalTypes } from '@/components/popups/modal';
import { User } from '@/types/schema';
import { getSessionCustomerFromPartnerId, getSessionPartnerId } from '@/utils/getPartnerId';
import { atom, PrimitiveAtom, useSetAtom, WritableAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { debounce } from 'lodash-es';
import { useCallback } from 'react';
import { getLocalCompanyId, getSessionCompanyId } from './getCompanyId';

export const sessionCompanyIdAtom = atom( getSessionCompanyId() );
export const localCompanyIdAtom = atom( getLocalCompanyId() );

export const sessionPartnerIdAtom = atom( getSessionPartnerId() );
export const sessionFromCustomerPartnerIdAtom = atom( getSessionCustomerFromPartnerId() );

export const userAtom = atom<User | undefined>( undefined );

export const staffAtom = atom( ( get ) =>
	get( userAtom )?.staffs?.find( ( staff ) => !staff?.isHidden && staff?.company?.id === getSessionCompanyId() ),
);
export const chatSettingsAtom = atomWithStorage( 'chatSettings', {
	minimizeChat: false,
	windowChat  : false,
	chatRoomID  : '',
} );

export const dashboardDataAtom = atomWithStorage<boolean | undefined>( 'concealDashboardData', undefined );

export const clientsMapAtom = atom( {} );

export const staffsMapAtom = atom( {} );

export const commercesMapAtom = atom( {} );

export const companyMapAtom = atom( {} );

export const unreadMessagesCountAtom = atom( 0 );

export const dismissibleBannerAtoms = atomWithStorage( 'banners', {
	hideVoPayBanner           : false,
	hideWidgetBanner          : false,
	hideScheduleTrainingBanner: false,
	hideCloverDeviceBanner    : false,
	hideSubscriptionBanner    : false,
	hideCardConnectBanner     : false,
	hideMaintenanceBannerDb   : false,
} );

export const dismissibleChangeLogModalAtoms = atomWithStorage<DismissibleChangeLogModalTypes>( 'changeLogModals', {
	hideSignAndTagModal: false,
} );

export const tableViewPreferencesAtom = atomWithStorage( 'tableViewPreferences', {
	invoicesTableShowCalendar: false,
	ordersTableShowCalendar  : false,
} );

export const lastFormAtom = atom<Record<string, any>>( {} );
export const lastPayloadAtom = atom<Record<string, any>>( {} );

// Helper hook to update atom fields without overwriting the entire object
export const useUpdateAtom = <T>(
	atom: PrimitiveAtom<T> | WritableAtom<T, [ T ], void>,
	options?: {
		debounce?: boolean,
		delay?: number
	},
) => {
	const setAtom = useSetAtom( atom );
	
	const { debounce: useDebounce = false, delay = 300 } = options || {};
	
	const updateAtom = useCallback( ( newValues: Partial<T> ) => {
		setAtom( ( prev ) => ( {
			...prev,
			...newValues,
		} ) );
	}, [ setAtom ] );
	
	const debouncedUpdateAtom = useCallback( debounce( updateAtom, delay ), [ updateAtom, delay ] );
	
	return useDebounce ? debouncedUpdateAtom : updateAtom;
};
