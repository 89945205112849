import { staffAtom, userAtom } from '@/utils/atoms';
import { useAtomValue, getDefaultStore } from 'jotai';

export default function useUserInfo() {
	const user = useAtomValue( userAtom );
	const staff = useAtomValue( staffAtom );
	return { user, staff };
}

export function getUserInfo() {
	const store = getDefaultStore();
	const user = store.get( userAtom );
	const staff = store.get( staffAtom );
	return { user, staff };
}

